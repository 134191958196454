#download-icon {
  font-size: 30px;
  color: black;
  opacity: 0.4;
}

#heading-card {
  margin-top: 40px;
  font-size: 45px;
}

.cardheader-text {
  color: #fff;
}

.card-text.sub-text-color {
  color: #8c8c8c;
}

#cardheader-subtext {
  font-size: 20px;
}

.card.cards-shadown {
  box-shadow: 2px 2px 30px #aaaaaa;
}

.card-image {
  max-height: 200px;
  object-fit: cover;
}

/* .col {
  padding: 20px 7px 0px 7px;
} */

.card.cards-shadown.cards-hover:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  z-index: 99;
}

